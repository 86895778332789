<template>
	<el-card :class="fullScreen ? 'plan-full-screen': ''">
		<advanced-search-bar>
			<template>
				<form-item label="选择日期:" style="width:300px;">
					<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
						end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:340px;" size="mini"
						:clearable="false">
					</el-date-picker>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					<el-button type="primary" size="mini" @click="downloadTask">下 载</el-button>
					<el-button type="primary" @click="showFilter" size="mini">筛 选</el-button>
					<el-button type="primary" @click="showEdit" size="mini">显示编辑</el-button>
					<el-button type="primary" @click="fullScreen=!fullScreen"
						size="mini">{{ fullScreen ? '缩小':'全屏'}}</el-button>
				</form-item>
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);">
		</div>
		<term-adjust-table ref="planTable" class="w-100" :tableData="filterData" :tableColumns="filterTableColumns"
			@handleSelectionChange="handleSelectionChange"
			:confirmJSCXTermAdjustPlan="confirmJSCXTermAdjustPlan"></term-adjust-table>
		<!-- <el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination> -->
		<table-columns-drawer :visible.sync="drawer.edit" :data="sortedTableColumns" :checkedKeys.sync="checkedKeys"
			:sortedKeys.sync="sortedKeys" :namespace="namespace + '_term_adjust'"
			:updateColumns="updateColumns"></table-columns-drawer>
		<el-drawer title="筛选" :visible.sync="drawer.filter" direction="rtl">
			<div style="padding: 0 20px;">
				<el-button type="primary" @click="applyFilter" size="mini">应 用</el-button>
				<div><small>Offers:</small></div>
				<div>
					<el-select v-model="filter.offers" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in offers" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
				<div><small>Directions:</small></div>
				<div>
					<el-select v-model="filter.directions" placeholder="" size="mini" filterable multiple>
						<el-option v-for="item in directions" :label="item" :value="item" :key="item"></el-option>
					</el-select>
				</div>
			</div>
		</el-drawer>
		<el-dialog title="确认替换" :visible.sync="dialog.confirm">
			<div>您将执行替换操作，继续请按确定</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialog.confirm=false" size="mini">取 消</el-button>
				<el-button type="primary" @click="confirmAction" size="mini">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import TermAdjustTable from '@/components/jscx/term-adjust-table.vue'
	import config from './minixs/term-adjust.js'
	import Moment from 'moment'
	import TableColumnsDrawer from '@/components/jscx/table-columns-drawer.vue'
	export default {
		mixins: [config],
		components: {
			TermAdjustTable,
			TableColumnsDrawer
		},
		inject: ['adminLayout'],
		data() {
			return {
				fullScreen: false,
				timeout: null,
				sortedKeys: [],
				checkedKeys: [],
				namespace: 'jscx',
				drawer: {
					edit: false,
					filter: false
				},
				dialog: {
					confirm: false
				},
				filter: {
					offers: [],
					directions: []
				},
				tableColumns: [{
						label: 'Offer Name',
						prop: 'offerName',
						disabled: false,
						sortable: false,
						width: 95
					}, {
						label: "Direction",
						prop: "direction",
						disabled: false,
						sortable: false,
						width: 78
					},
					{
						label: "term",
						prop: "term",
						disabled: false,
						sortable: false,
						width: 80
					},
					{
						label: "L7d_Conversions",
						prop: "l3dConversions",
						disabled: false,
						sortable: false,
						width: 60
					},
					{
						label: "L7d_Epc",
						prop: "l3dEpc",
						disabled: false,
						sortable: false,
						width: 60
					},
					{
						label: "Status",
						prop: "termStatus",
						disabled: false,
						sortable: false,
						width: 60
					},
					{
						label: "Offer L7d Average epc",
						prop: "offerL3dAvgEpc",
						disabled: false,
						sortable: false,
						width: 60
					},
					{
						label: "替换时间",
						prop: "replaceHours",
						disabled: false,
						sortable: false,
						width: 60
					},
					{
						label: "替换时间内的转化数",
						prop: "replaceConversions",
						disabled: false,
						sortable: false,
						width: 60
					},
					{
						label: "调整计划",
						prop: "plan",
						disabled: true,
						sortable: false,
						width: 100
					},
					{
						label: "Term New Status",
						prop: "termNewStatus",
						disabled: true,
						sortable: false,
						width: 100
					},
					{
						label: "Replace Term",
						prop: "replaceTerm",
						disabled: true,
						sortable: false,
						width: 100
					},
					{
						label: "Replace Term L7d_Conversions",
						prop: "replaceTermL3dConversion",
						disabled: true,
						sortable: false,
						width: 100
					},
					{
						label: "New Term L7d_Epc",
						prop: "replaceTermL3dEpc",
						disabled: true,
						sortable: false,
						width: 100
					},
					{
						label: "操作",
						prop: 'status',
						disabled: true,
						sortable: false,
						width: 80
					}
				],
				filterData: [],
				sortedTableColumns: [],
				filterTableColumns: [],
				selectedRow: null
			}
		},
		created() {
			const checkedKeys = localStorage.getItem(
				`${this.namespace}_term_adjust_${process.env.VUE_APP_VERSION}_checked_keys`)
			if (checkedKeys) {
				try {
					this.checkedKeys = JSON.parse(checkedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.checkedKeys = this.tableColumns.map(v => v.prop)
			}
			const sortedKeys = localStorage.getItem(
				`${this.namespace}_term_adjust_${process.env.VUE_APP_VERSION}_sorted_keys`)
			if (sortedKeys) {
				try {
					this.sortedKeys = JSON.parse(sortedKeys)
				} catch (e) {
					console.log(e)
				}
			} else {
				this.sortedKeys = this.tableColumns.map(v => v.prop)
			}
			this.updateColumns()
		},
		computed: {
			offers() {
				return [...new Set(this.tableData.map(v => v.offerName))]
			},
			directions() {
				return [...new Set(this.tableData.map(v => v.direction || 'Others'))]
			}
		},
		watch: {
			fullScreen(newVal, oldVal) {
				this.$nextTick(() => {
					this.$refs.planTable.doLayout()
				})
			},
			tableData(newVal, oldVal) {
				this.filterData = [...newVal]
				this.filter.offers = []
				this.filter.directions = []
			}
		},
		methods: {
			updateColumns() {
				this.sortedTableColumns = this.tableColumns.reduce((lhs, rhs) => {
					const index = this.sortedKeys.indexOf(rhs.prop)
					if (index !== -1) {
						lhs[index] = rhs
					}
					return lhs
				}, new Array(this.tableColumns.length))
				this.filterTableColumns = this.sortedTableColumns.reduce((lhs, rhs) => {
					if (this.checkedKeys.indexOf(rhs.prop) !== -1) {
						lhs.push(rhs)
					}
					return lhs
				}, [])
			},
			showEdit() {
				this.drawer.edit = true
			},
			confirmJSCXTermAdjustPlan(row) {
				this.selectedRow = row
				this.dialog.confirm = true
			},
			confirmAction() {
				this.dialog.confirm = false
				const id = this.selectedRow.offerId
				const date = this.selectedRow.costDate
				this.adminLayout.showLoading()
				this.$store.dispatch(`${this.namespace}/confirmJSCXTermAdjustPlan`, {id, date})
				.then((res) => {
					this.adminLayout.hideLoading()
					this.getList()
				}).catch(error => {
					this.adminLayout.hideLoading()
					this.$showErrMsg(error)
				});
			},
			showFilter() {
				this.drawer.filter = true
			},
			applyFilter() {
				this.filterData = this.tableData.reduce((lhs, rhs) => {
					const res0 = this.filter.offers.length === 0 || this.filter.offers.length > 0 && this
						.filter.offers.includes(rhs.offerName)
					const res1 = this.filter.directions.length === 0 || this.filter.directions.length > 0 && this
						.filter.directions.includes(rhs.direction || 'Others')
					if (res0 && res1) {
						lhs.push(rhs)
					}
					return lhs
				}, [])
				this.drawer.filter = false
			}
		}
	}
</script>

<style>
	.plan-full-screen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}
</style>